import React from 'react'

interface Props {
  fill: string
  stroke: string
  [key: string]: any
}

export default function Icon(props: Props) {
  const { fill, stroke } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      {...props}
      fill="none"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth={1.5}
        d="M12 21.75v0c-.96 0-1.882-.381-2.56-1.06l-.697-.697a4.243 4.243 0 0 0-3-1.243H3.09A1.591 1.591 0 0 1 1.5 17.159v0c0-.422.168-.827.466-1.125l.284-.284.013-.013a5.076 5.076 0 0 1 1.704-1.124v0c.6-.24 1.24-.363 1.886-.363H6"
      />
      <path
        fill={fill}
        d="M17.465 3.624a2.813 2.813 0 0 1 5.211 2.117l-.07.174a.375.375 0 0 1-.489.206l-4.516-1.835a.375.375 0 0 1-.207-.489l.07-.173ZM16.449 6.125a.45.45 0 0 1 .586-.248l4.377 1.779a.45.45 0 0 1 .248.586l-3.786 9.32a3.6 3.6 0 0 1-1.64 1.82l-.821.438a1.8 1.8 0 0 1-2.562-1.04l-.283-.886a3.6 3.6 0 0 1 .095-2.45l3.786-9.32Z"
      />
    </svg>
  )
}
