import React from 'react'
import { router, usePage } from '@inertiajs/react'
import { logout } from '../../actions/auth'
import { useIsAuthenticated } from '../../hooks/auth'
import Button from '../Button/Button'
import Icon from '../Icon/Icon'
import styles from './HeaderNav.module.scss'

export default function HeaderNav({
  setOpenLogin,
  setOpenNewBill,
}: {
  setOpenLogin: (openLogin: boolean) => void
  setOpenNewBill: (openNewBill: boolean) => void
}) {
  const isAuthentificated = useIsAuthenticated()
  const url = usePage().props.url as string
  const campaign = usePage().props.campaign as boolean

  return (
    <nav className={styles.nav}>
      <a className={`${url === '/' || url === '/popular-bills' ? styles.active : ''} ${styles.hideMobile}`} onClick={() => router.get('/')}>Se alle forslag</a>

      {isAuthentificated && isAuthentificated.bills.data.length > 0 && (
        <a
          className={`${url === '/my-bills' ? styles.active : ''} ${styles.hideMobile}`}
          onClick={() => router.get('/my-bills')}
        >
          Dine forslag
        </a>
      )}

      <a
        className={`${url === '/about' ? styles.active : ''} ${styles.hideMobile}`}
        onClick={() => router.get('/about')}
      >
        Om Ultraforslag
      </a>

      {campaign && (isAuthentificated
        ? (
          <>

            <a
              className={styles.button}
              onClick={() => {
                logout(url)
              }}
            >
              Log ud
            </a>
          </>
          )
        : (
          <a
            className={styles.button}
            onClick={() => {
              setOpenLogin(true)
            }}
          >
            Log ind
          </a>
          ))}

      {campaign && (
        <div className={styles.newBillbutton}>
          <Button
            text="Skriv dit forslag"
            icon={<Icon name="pen" stroke="#FFF" fill="#FFF" />}
            action={() => setOpenNewBill(true)}
          />
        </div>
      )}
    </nav>
  )
}
