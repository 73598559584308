import React from 'react'
import Headline from '../Headline/Headline'
import styles from './Hero.module.scss'
import HeroVideo from './HeroVideo'

export default function Hero() {
  const [showVideo, setShowVideo] = React.useState(false)

  return (
    <div className={styles.heroOuter}>

      <div className={styles.hero}>
        <Headline text="Hvad vil du gerne forandre?" />
        {/* <p>Skriv dit Ultraforslag til dét, DU gerne vil forandre. Du kan også stemme på andres forslag og dele et link til dit eget, så du får stemmer på det.</p> */}
        {/* <p>Et af de forslag med flest stemmer skal nemlig præsenteres for statsministeren til grundlovsdag.</p> */}
        {/* <p>Ultraforslag er børn og unges mulighed for at skrive forslag til forandring og blive hørt af Danmarks toppolitikere.</p>
        <p>Årets Ultraforslag er ved at blive fundet blandt de flere tusinde børn og unge, der har skrevet forslag og stemt. Afstemningen er derfor lukket indtil den 5. juni, hvor vinderen offentliggøres, og det igen er muligt at indsende og stemme på Ultraforslag.</p> */}

        <p>Ultraforslag giver børn og unge en stemme.</p>
        <p>Skriv dit Ultraforslag til dét, DU gerne vil forandre. Du kan også stemme på andres forslag og dele et link til dit eget, så du får stemmer på det. </p>

        <div className={styles.howItWorks} onClick={() => setShowVideo(true)}>
          <div className={styles.play}>
            <img src="/videos/praemisvideo_2.jpg" alt="Ultraforslag illustration" width="80px" />
            <img className={styles.playImg} src="/images/play.png" alt="Afspil video" width="30px" />
          </div>
          <div className={styles.text}>
            <p>Se her hvordan det virker</p>
            <p className={styles.length}>(Varighed 1 min og 17 sek)</p>
          </div>
        </div>

      </div>

      {showVideo && <HeroVideo isOpen={showVideo} setIsOpen={setShowVideo} />}
    </div>
  )
}
