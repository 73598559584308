import React, { useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import OtpInput from 'react-otp-input'
import { usePage } from '@inertiajs/react'
import Overlay from '../Overlay/Overlay'
import Input from '../Form/Input/Input'
import Button from '../Button/Button'
import { exists, signup } from '../../actions/auth'
import Checkbox from '../Form/Checkbox/Checkbox'
import styles from './Auth.module.scss'

export default function Signup({
  isOpen,
  setIsOpen,
  setOpenLogin,
  setOpenConsent,
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  setOpenLogin: (openLogin: boolean) => void
  setOpenConsent: (openConsent: boolean) => void
}) {
  const url = usePage().props.url as string
  const searchParams = new URLSearchParams(window.location.search)
  const mobileFromLogin = searchParams.has('unknown-login') ? searchParams.get('unknown-login') : false

  const [step, setStep] = useState(1)
  const [stepExttinng, setStepExttinng] = useState(1)
  const [isFetching, setIsFetching] = useState(false)
  const [fetchError, setFetchError] = useState(false)

  const [mobile, setMobile] = useState('')
  const [pin, setPin] = useState('')
  const [repeatPin, setRepeatPin] = useState('')
  const [permission, setPermission] = useState(false)
  const [permissionError, setPermissionError] = useState<string | false>(false)

  const [headerText, setHeaderText] = useState<string>('Opret en bruger ved at skrive dit telefonnummer')

  const permissionLabel = (
    <>
      {`Jeg bekræfter, at jeg er over 18 år og har læst, forstået og accepteret
      `}
      <a onClick={() => {
        setTimeout(() => {
          setPermission(permission)
        }, 200)
        setOpenConsent(true)
      }}
      >
        Betingelserne for 'Ultraforslag',
      </a>
      {` samt DR's generelle `}
      <a
        href="https://www.dr.dk/om-dr/dr-og-dine-data/drs-privatlivspolitik"
        target="_blank"
        onClick={() => {
          setTimeout(() => {
            setPermission(permission)
          }, 200)
        }}
      >
        Privatlivspolitik.
      </a>
      {` Jeg indestår for, at forældremyndighedsindehaverne til det barn, der indsender Ultraforslaget, har givet tilladelse til indsendelsen i henhold til betingelserne.
      Ved delt/fælles forældremyndighed skal begge forældremyndighedsindehavere samtykke.`}
    </>
  )

  const variants = {
    enter: (direction: number) => {
      return {
        x: direction > stepExttinng ? 100 : -100,
        opacity: 0,
      }
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction: number) => {
      setStepExttinng(step)

      return {
        zIndex: 0,
        x: step > direction ? 100 : -100,
        opacity: 0,
      }
    },
  }

  const handleSignup = async (e?: React.FormEvent) => {
    e?.preventDefault()
    setIsFetching(true)

    const fetch = await signup(mobile, pin, url)
    if (!fetch) {
      setFetchError(true)
      setStep(1)
      setMobile('')
      setPin('')
      setRepeatPin('')
    }

    setIsFetching(false)
  }

  const handleCheckExist = async (e?: React.FormEvent) => {
    e?.preventDefault()
    setIsFetching(true)
    setPermissionError(false)

    if (!permission) {
      setPermissionError('Du skal have lov af dine forældre')
      return
    }

    const doesExists = await exists(mobile)

    if (doesExists === 200)
      setFetchError(true)

    if (doesExists === 404)
      setStep(2)

    setIsFetching(false)
  }

  useEffect(() => {
    if (isOpen && step === 1)
      document.getElementById('mobile')?.focus()
  }, [step, isOpen])

  useEffect(() => {
    if (repeatPin.length === 4 && step === 3 && repeatPin === pin)
      void handleSignup()
  }, [repeatPin])

  useEffect(() => {
    if (mobileFromLogin) {
      setMobile(mobileFromLogin)
      setHeaderText('Dit telefonnummer er ikke tilmeldt. Opret en bruger her istedet')
    }
  }, [mobileFromLogin])

  return (
    <Overlay isOpen={isOpen} background="turquoise" setIsOpen={setIsOpen} title="OPRET BRUGER">
      <AnimatePresence initial={false} custom={step} mode="wait">
        {step === 1 && (
          <motion.form
            className={styles.step}
            key="step1"
            custom={1}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ x: { type: 'spring', stiffness: 300, damping: 30 }, opacity: { duration: 0.2 } }}
          >
            <div className={styles.form}>
              <h2 className={styles.header}>{headerText}</h2>

              <Input
                type="tel"
                label="Dit telefonnummer"
                placeholder="Indtast dit telefonnummer"
                name="mobile"
                value={mobile}
                setValue={setMobile}
                white={true}
                whiteBackground={true}
                maxLength={8}
              />

              {fetchError && (
                <div className={`${styles.text} ${styles.error}`}>
                  <p>Telefonnummer er allerede tilmeldt</p>
                </div>
              )}

              <div className={styles.permission}>
                <Checkbox
                  label={permissionLabel}
                  name="permission"
                  value={permission}
                  setValue={setPermission}
                  error={permissionError}
                  setError={setPermissionError}
                />
              </div>

              <div className={styles.text}>
                <p>Har du allerede en bruger?</p>
                <a onClick={() => {
                  setIsOpen(false)
                  setOpenLogin(true)
                }}
                >
                  Log ind
                </a>
              </div>

            </div>
            <div className={styles.actions}>
              <Button
                submit={true}
                text="Forsæt"
                disabled={mobile.length !== 8}
                action={handleCheckExist}
              />
            </div>

          </motion.form>
        )}

        {step === 2 && (
          <motion.form
            className={styles.step}
            key="step2"
            custom={2}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ x: { type: 'spring', stiffness: 300, damping: 30 }, opacity: { duration: 0.2 } }}
          >
            <div className={styles.form}>
              <h2 className={styles.header}>Vælg en PINKODE</h2>

              <div className={styles.pin}>
                <OtpInput
                  value={pin}
                  onChange={setPin}
                  shouldAutoFocus={true}
                  numInputs={4}
                  renderInput={props => <input {...props} />}
                  inputType="tel"
                />
              </div>

              <div className={styles.text}>
                <p>Det er en god ide at vælge en pinkode du nemt kan huske, eller du kan skrive den ned.</p>
              </div>

            </div>

            <div className={styles.actions}>
              <Button
                text="Tilbage"
                alternative={true}
                action={() => setStep(1)}
              />
              <Button
                submit={true}
                text="Forsæt"
                disabled={pin.length !== 4}
                action={(e: React.MouseEvent) => {
                  e.preventDefault()
                  setStep(3)
                }}
              />
            </div>

          </motion.form>
        )}

        {step === 3 && (
          <motion.form
            className={styles.step}
            key="step3"
            custom={2}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ x: { type: 'spring', stiffness: 300, damping: 30 }, opacity: { duration: 0.2 } }}
          >
            <div className={styles.form}>
              <h2 className={styles.header}>Gentag PINKODEN</h2>

              <div className={styles.pin}>
                <OtpInput
                  value={repeatPin}
                  onChange={setRepeatPin}
                  shouldAutoFocus={true}
                  numInputs={4}
                  renderInput={props => <input {...props} />}
                  inputType="tel"
                />
              </div>

              {repeatPin.length === 4 && repeatPin !== pin && (
                <div className={`${styles.text} ${styles.error}`}>
                  <p>PINKODERNE skal være ens!</p>
                </div>
              )}
            </div>

            <div className={styles.actions}>
              <Button
                text="Tilbage"
                alternative={true}
                action={() => setStep(2)}
              />
              <Button
                submit={true}
                text={`${isFetching ? 'Opretter bruger...' : 'Opret bruger'}`}
                disabled={isFetching || repeatPin.length !== 4 || repeatPin !== pin}
                action={handleSignup}
              />
            </div>

          </motion.form>
        )}
      </AnimatePresence>
    </Overlay>
  )
}
