import React, { useEffect, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import OtpInput from 'react-otp-input'
import { router, usePage } from '@inertiajs/react'
import Overlay from '../Overlay/Overlay'
import Input from '../Form/Input/Input'
import Button from '../Button/Button'
import { forgotPin, login } from '../../actions/auth'
import styles from './Auth.module.scss'

export default function Login({
  isOpen,
  setIsOpen,
  setOpenSignup,
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  setOpenSignup: (openSignup: boolean) => void
}) {
  const url = usePage().props.url as string

  const [step, setStep] = useState(1)
  const [stepExttinng, setStepExttinng] = useState(1)
  const [mobile, setMobile] = useState('')
  const [pin, setPin] = useState('')
  const [authError, setAuthError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [forgotPinText, setForgotPinText] = useState<string | false>(false)

  const variants = {
    enter: (direction: number) => {
      return {
        x: direction > stepExttinng ? 100 : -100,
        opacity: 0,
      }
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction: number) => {
      setStepExttinng(step)

      return {
        zIndex: 0,
        x: step > direction ? 100 : -100,
        opacity: 0,
      }
    },
  }

  const handleLogin = async (e?: React.FormEvent) => {
    e?.preventDefault()
    setIsFetching(true)
    setAuthError(false)
    const auth = await login(mobile, pin, url)

    if (auth === 404) {
      router.get(`${url}?unknown-login=${mobile}`, {}, {
        preserveScroll: true,
        preserveState: true,
      })

      setOpenSignup(true)
      setIsOpen(false)
      setStep(1)
      setMobile('')
      setPin('')
    }

    if (auth === 401)
      setAuthError(true)

    setIsFetching(false)
  }

  const handleForgotPin = async (e?: React.FormEvent) => {
    e?.preventDefault()
    setIsFetching(true)

    if (mobile.length !== 8) {
      setIsFetching(false)
      setForgotPinText('Indtast venligst et gyldigt telefonnummer.')
      return
    }

    await forgotPin(mobile)
    setForgotPinText('Vi sender dig en sms med din pinkode inden én hverdag.')
    setIsFetching(false)
  }

  useEffect(() => {
    setForgotPinText(false)
  }, [mobile])

  useEffect(() => {
    if (isOpen && step === 1)
      document.getElementById('mobile')?.focus()
  }, [step, isOpen])

  useEffect(() => {
    if (pin.length === 4 && step === 2 && !authError)
      void handleLogin()
  }, [pin])

  return (
    <Overlay isOpen={isOpen} background="turquoise" setIsOpen={setIsOpen} title="Log ind">
      <AnimatePresence initial={false} custom={step} mode="wait">
        {step === 1 && (
          <motion.form
            className={styles.step}
            key="step1"
            custom={1}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ x: { type: 'spring', stiffness: 300, damping: 30 }, opacity: { duration: 0.2 } }}
          >
            <div className={styles.form}>
              <h2 className={styles.header}>LOG IND VED AT SKRIVE DIT TELEFONNUMMER</h2>

              <Input
                type="tel"
                label="Dit telefonnummer"
                placeholder="Indtast dit telefonnummer"
                name="mobile"
                value={mobile}
                setValue={setMobile}
                white={true}
                whiteBackground={true}
                maxLength={8}
              />

              <div className={styles.text}>
                {forgotPinText || (
                  <>
                    <p>
                      Har du glemt din pinkode?
                      {' '}
                      <a onClick={handleForgotPin as () => void}>klik her</a>
                    </p>
                  </>
                )}
              </div>

              <div className={styles.text}>
                <p>Har du endnu ikke en bruger?</p>
                <a onClick={() => {
                  setIsOpen(false)
                  setOpenSignup(true)
                }}
                >
                  Opret bruger
                </a>
              </div>

            </div>
            <div className={styles.actions}>
              <Button
                submit={true}
                text="Forsæt"
                disabled={mobile.length !== 8}
                action={(e: React.MouseEvent) => {
                  e.preventDefault()
                  setStep(2)
                }}
              />
            </div>

          </motion.form>
        )}

        {step === 2 && (
          <motion.form
            className={styles.step}
            key="step2"
            custom={2}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{ x: { type: 'spring', stiffness: 300, damping: 30 }, opacity: { duration: 0.2 } }}
          >
            <div className={styles.form}>
              <h2 className={styles.header}>SKRIV DIN PINKODE</h2>

              <div id="pin" className={`${styles.pin} ${authError ? styles.authError : ''}`}>
                <OtpInput
                  value={pin}
                  onChange={setPin}
                  shouldAutoFocus={true}
                  numInputs={4}
                  inputType="tel"
                  renderInput={props => <input {...props} />}
                />
              </div>

              {authError && (
                <div className={styles.text}>
                  <p>Forkert telefonnummer eller PIN-kode</p>
                </div>
              )}

              <div className={styles.text}>
                {forgotPinText || (
                  <>
                    <p>
                      Har du glemt din Pinkode?
                      {' '}
                      <a onClick={handleForgotPin as () => void}>klik her</a>
                    </p>
                  </>
                )}
              </div>

            </div>

            <div className={styles.actions}>
              <Button
                text="Tilbage"
                alternative={true}
                action={() => setStep(1)}
              />
              <Button
                submit={true}
                text={`${isFetching ? 'Logger ind..' : 'Log ind'}`}
                disabled={isFetching || pin.length !== 4}
                action={handleLogin}
              />
            </div>

          </motion.form>
        )}
      </AnimatePresence>
    </Overlay>
  )
}
