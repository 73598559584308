import React from 'react'
import { router } from '@inertiajs/react'
import Headline from '../../components/Headline/Headline'
import Layout from '../Layout'
import Video from '../../components/Video/Video'
import styles from './page.module.scss'

export default function Page() {
  const openConsent = () => {
    router.get('/about?open-consent', {}, {
      preserveScroll: true,
    })
  }

  return (
    <Layout>
      <div className={styles.header}>
        <Headline text="OM ULTRAFORSLAG" />
      </div>

      <div className={styles.content}>
        <h4>Hvad er Ultraforslag?</h4>
        <p>Ultraforslag er børn og unges forslag til forandring. Hvis du er under 18 år, så er det DIN mulighed for at stemme, bestemme og blive hørt. Med Ultraforslag kan du skrive dit eget forslag til dét, DU gerne vil forandre. Du kan også stemme på andres gode forslag, og du kan dele et link til dit forslag, så du får stemmer på det.</p>
        <p>Det er din mulighed for at blive hørt af Folketinget og Danmarks toppolitikere.</p>

        <h4>Hvem kan deltage og hvordan?</h4>
        <p>Alle stemmer tæller - du skal bare være under 18 år for at stemme og bestemme hos Ultra. Så hvis du ønsker at stille et Ultraforslag eller stemme på Ultraforslag, skal du være under 18 år.</p>
        <p>For at oprette et forslag og stemme på andres skal du være logget ind på hjemmesiden. Du logger ind med dit telefonnummer og laver din egen 4-cifrede pinkode. Husk din pinkode til næste gang du logger ind.</p>
        <p>Du kan godt indsende flere Ultraforslag, men du skal altså oprette ét forslag ad gangen. Du kan stemme på alle Ultraforslag én gang. Du kan ikke stemme flere gange på det samme forslag.</p>
        <p>Ultraforslag må IKKE indeholde hadbeskeder, oplysninger om identificerbare personer, mobning, indhold, der i øvrigt er i strid med lovgivningen eller lignende. Hvis forslaget gør det, bliver det ikke offentliggjort og kan altså ikke findes på hjemmesiden eller få stemmer.</p>
        <p>Hver uge offentliggøres der nye forslag, på nær i skolernes ferier.</p>
        <p>Der skal være en person på minimum 18 år, der hjælper dig med at indsende forslaget.</p>

        <Video name="praemisvideo_2" length="(Varighed 1 min og 17 sek)" />

        <h4>SAMARBEJDSPARTNERE</h4>
        <p>'Ultraforslag' er et samarbejde mellem DR Ultra, DR Lær, Folketinget og landets biblioteker.</p>

        <h4>MØD ULTRAFORSLAG I SKOLEN</h4>
        <p>
          DR Lær har lavet undervisningsforløb, som klasser kan bruge til at arbejde med Ultraforslag. I forløbet finder du blandt andet video-explainers og sjove øvelser, som klæder elever på til at finde på egne forslag og stemme på andres. Formålet med forløbet er at give eleverne erfaring med demokratiske processer og styrke deres demokratiske selvtillid. Find forløbet
          {' '}
          <a href="https://www.dr.dk/skole/dansk/mellemtrin/tema/ultraforslag" target="_blank">her</a>
          .
        </p>

        <Video name="folketingsvideo_2" length="(Varighed 3 min og 15 sek)" />

        <h4>BETINGELSERNE FOR ULTRAFORSLAG</h4>
        <p>
          <a onClick={openConsent}>Læs her vilkårsbetingelserne for deltagelse i Ultraforslag.</a>
        </p>

        <h4></h4>
        <p>
          Har du spørgsmål eller brug for hjælp til Ultraforslag, kan du skrive til
          {' '}
          <a href="mailto:DRUltraforslag@dr.dk" target="_blank">DRUltraforslag@dr.dk.</a>
        </p>

      </div>

    </Layout>
  )
}
