import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion'
import Cookies from 'js-cookie'
import { router, usePage } from '@inertiajs/react'
import Headline from '../Headline/Headline'
import Icon from '../Icon/Icon'
import { useIsAuthenticated } from '../../hooks/auth'
import type { Bill } from '../../types/bill.type'
import { vote } from '../../actions/vote'
import Place from '../Place/Place'
import Button from '../Button/Button'
import type { Campaign } from '../../types/campaign.types'
import styles from './Bill.module.scss'

export default function BillCard({
  bill,
  withPlace,
  hasVoteHandling = true,
  shareable,
}: {
  bill: Bill
  withPlace?: boolean
  hasVoteHandling?: boolean
  shareable?: boolean
}) {
  const isAuthentificated = useIsAuthenticated()
  const url = usePage().props.url as string
  const campaign = usePage().props.campaign as Campaign | undefined

  const [isVoted, setIsVoted] = useState(isAuthentificated ? isAuthentificated.votes.includes(bill.id) : false)
  const [fire, setFire] = useState(false)
  const [votes, setVotes] = useState(bill.votes)
  const [isFetching, setIsFetching] = useState(false)
  const [shared, setShared] = useState(false)

  const handleVote = async () => {
    await vote(bill.id)
  }

  useEffect(() => {
    const vote = Cookies.get('vote')

    if (isAuthentificated && (vote && vote === bill.id.toString())) {
      setIsFetching(true)

      setTimeout(() => {
        Cookies.remove('vote')

        if (isAuthentificated.votes.includes(bill.id)) {
          setFire(true)
        }
        else {
          void (hasVoteHandling && handleVote())
          setIsVoted(!isVoted)
          setFire(!isVoted)
          setVotes(votes + (isVoted ? -1 : 1))
        }

        setIsFetching(false)
      }, 500)
    }
  }, [])

  const handleVoteClick = async () => {
    setIsFetching(true)

    if (!isAuthentificated) {
      Cookies.set('vote', bill.id.toString())

      router.get(url, { login: true }, {
        preserveScroll: true,
        onSuccess: (page) => {
          window.history.replaceState({}, '', page.props.url as string)
        },
      })
      return
    }

    await handleVote()
    setIsVoted(!isVoted)
    setFire(!isVoted)
    setVotes(votes + (isVoted ? -1 : 1))
    setIsFetching(false)
  }

  return (
    <div className={`${styles.bill} ${styles[bill.color]} ${withPlace ? styles.withPlace : ''}`}>
      {withPlace && <Place number={bill.placement} />}
      <div className={styles.emoji}>{bill.emoji}</div>
      <div className={styles.sender}>{`${bill.firstname}, ${bill.city}`}</div>
      <Headline text={bill.title} />
      <div className={styles.text}>
        <p>
          {bill.text}
          {!bill.text.endsWith('.') ? '.' : ''}
        </p>
        <p>
          {bill.reason}
          {!bill.reason.endsWith('.') ? '.' : ''}
        </p>
      </div>
      {!bill.preview && (
        <div className={styles.bottom}>
          <div className={styles.votes}>
            <div className={styles.count}>{votes}</div>
            {`${votes === 1 ? 'Stemme' : 'Stemmer'}`}
          </div>

          {campaign && hasVoteHandling && campaign.id === bill.campaign_id && (
            <div className={styles.voteButtonWrapper}>
              {fire && (
                <motion.img
                  className={styles.fire}
                  src="/images/fire.png"
                  initial={{ opacity: 0, y: 0 }}
                  animate={{
                    opacity: [1, 0],
                    y: [0, -100],
                  }}
                  transition={{
                    duration: 1,
                    yoyo: Number.POSITIVE_INFINITY,
                  }}
                />
              )}
              <button
                className={styles.voteButton}
                onClick={handleVoteClick as () => void}
                disabled={isFetching}
              >
                <div className={styles.inner}>{isFetching ? '...' : isVoted ? <Icon name="x" /> : 'Stem'}</div>
              </button>
            </div>
          )}
        </div>
      )}

      {shareable && (
        <div className={styles.share}>
          <Button
            text="Del dit forslag"
            action={() => {
              navigator.clipboard.writeText(`${window.location.protocol}//${window.location.host}/bills/${bill.id}`)
                .then(() => setShared(true))
                .catch(err => console.error('Could not copy URL: ', err))
            }}
          />
          {shared && <div className={styles.shared}>Link dit forslag er nu kopieret til udklipsholderen!</div>}
        </div>
      )}
    </div>
  )
}
