import React, { useEffect, useState } from 'react'

import { Head, usePage } from '@inertiajs/react'

import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import Login from '../components/Auth/Login'
import Signup from '../components/Auth/Signup'
import NavMobile from '../components/NavMobile/NavMobile'
import NewBill from '../components/NewBill/NewBill'
import Button from '../components/Button/Button'
import Icon from '../components/Icon/Icon'
import Consent from '../components/Consent/Consent'
import Paused from '../components/Paused/Paused'
import styles from './Layout.module.scss'

export default function Layout({ headerTitle, children }: {
  headerTitle?: string
  children: React.ReactNode
}) {
  const campaign = usePage().props.campaign
  const url = usePage().props.url as string
  const searchParams = new URLSearchParams(window.location.search)

  const { login } = usePage().props
  const [openNav, setOpenNav] = useState(false)
  const [openNewBill, setOpenNewBill] = useState(false)
  const [openLogin, setOpenLogin] = useState(!!login)
  const [openSignup, setOpenSignup] = useState(false)
  const [openPausedScreen, setOpenPausedScreen] = useState(!campaign)
  const [openConsent, setOpenConsent] = useState(searchParams.has('open-consent'))

  useEffect(() => {
    if (openSignup || openNav || openLogin || openNewBill || openConsent) {
      document.body.classList.add('no-scroll')
    }
    else {
      document.body.classList.remove('no-scroll')
      window.history.replaceState({}, '', url)
    }
  }, [openSignup, openNav, openLogin, openNewBill, openConsent])

  return (
    <>
      <Head>
        <link rel="icon" type="image/png" href="/images/ultra.png" />
        <title>Ultraforslag</title>
      </Head>

      <Header openNav={openNav} setOpenNav={setOpenNav} setOpenLogin={setOpenLogin} setOpenNewBill={setOpenNewBill} title={headerTitle} />

      <main>
        {children}
      </main>

      {campaign && (
        <div className={styles.newBillButton}>
          <Button
            text="Skriv dit forslag"
            icon={<Icon name="pen" stroke="#FFF" fill="#FFF" />}
            action={() => setOpenNewBill(true)}
          />
        </div>
      )}

      <Footer />
      <NavMobile isOpen={openNav} setIsOpen={setOpenNav} setOpenLogin={setOpenLogin} setOpenNewBill={setOpenNewBill} />

      <Paused isOpen={openPausedScreen} setIsOpen={setOpenPausedScreen} />

      {campaign && (
        <>
          <NewBill isOpen={openNewBill} setIsOpen={setOpenNewBill} setOpenConsent={setOpenConsent} />
          <Login isOpen={openLogin} setIsOpen={setOpenLogin} setOpenSignup={setOpenSignup} />
          <Signup isOpen={openSignup} setIsOpen={setOpenSignup} setOpenLogin={setOpenLogin} setOpenConsent={setOpenConsent} />
        </>
      )}

      <Consent isOpen={openConsent} setIsOpen={setOpenConsent} />
    </>
  )
}
