import React, { useEffect, useRef, useState } from 'react'
import styles from './Video.module.scss'

export default function Video({
  name,
  length,
  autoplay = false,
  controls = true,
  loop = false,
  title,
}: {
  name: string
  length?: string
  autoplay?: boolean
  controls?: boolean
  loop?: boolean
  title?: string
}) {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isPlaying, setIsPlaying] = useState(autoplay)

  const handleOnPlay = () => {
    setIsPlaying(true)
  }

  const handleOnPause = () => {
    setIsPlaying(false)
  }

  const handleOnClick = () => {
    if (!controls)
      return

    if (videoRef.current) {
      if (isPlaying) {
        setIsPlaying(false)
        videoRef.current.pause()
      }
      else { videoRef.current.play() }
    }
  }

  return (
    <div className={styles.video}>
      <video
        onPlay={handleOnPlay}
        onPause={handleOnPause}
        onClick={handleOnClick}
        ref={videoRef}
        className={styles.player}
        controls={controls && isPlaying}
        poster={`/videos/${name}.jpg`}
        autoPlay={autoplay}
        loop={loop}
        playsInline
      >
        <source src={`/videos/${name}.mp4`} type="video/mp4" />
      </video>

      {!isPlaying && <div className={styles.title}>{title}</div>}
      {!isPlaying && !autoplay && <img onClick={handleOnClick} className={styles.playButton} src="/images/play.png" alt="Video play" />}

      <div className={styles.length}>{length}</div>
    </div>
  )
}
