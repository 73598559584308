import React, { useState } from 'react'
import { router } from '@inertiajs/react'
import { motion } from 'framer-motion'

import type { pageProps } from '../types/page.type'
import type { Bill } from '../types/bill.type'
import BillCard from '../components/Bill/Bill'
import Button from '../components/Button/Button'
import styles from './printBills.module.scss'

export default function Page({ bills: pageBills }: pageProps) {
  const [bills, setBills] = useState(pageBills.data)
  // const [links, setLinks] = useState(pageBills.links)
  const [isFetching, setIsFetching] = useState(false)

  const gotoPage = (direction: string) => {
    const link = direction === 'next' ? pageBills.links.next : pageBills.links.prev

    if (!link)
      return

    setIsFetching(true)
    router.get(link, {}, {
      onSuccess: (page: any) => {
        // eslint-disable-next-line ts/no-unsafe-assignment, ts/no-unsafe-member-access
        const newBills: Bill[] = page.props.bills.data

        setBills([...newBills])

        setTimeout(() => {
          setIsFetching(false)
        }, 200)
      },
    })
  }

  return (
    <>
      <div className={styles.bills}>
        {bills.map((bill: Bill) => {
          return (
            <div key={bill.placement} className={styles.bill}>
              <motion.div
                initial={{ y: '100%', opacity: 0 }}
                animate={{ y: '0%', opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <BillCard bill={bill} withPlace={false} hasVoteHandling={false} />
              </motion.div>
            </div>
          )
        })}
      </div>

      <div className={styles.actions}>
        <Button
          action={() => gotoPage('prev')}
          disabled={isFetching || !pageBills.links.prev}
          text="Previous page"
        />

        <Button
          action={() => gotoPage('next')}
          disabled={isFetching || !pageBills.links.next}
          text={isFetching ? 'Loading...' : 'Next page'}
        />

      </div>

    </>
  )
}
