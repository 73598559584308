import React from 'react'
import BillCard from '../Bill/Bill'
import Button from '../Button/Button'
import type { Bill, BillColor } from '../../types/bill.type'
import styles from './NewBill.module.scss'

export default function Step3({
  setStep,
  color,
  emoji,
  title,
  text,
  reason,
  firstname,
  city,
}: {
  setStep: (step: number) => void
  color: BillColor
  emoji: string
  title: string
  text: string
  reason: string
  firstname: string
  city: string
}) {
  const previewBill: Bill = {
    id: 0,
    firstname,
    emoji,
    city,
    title,
    text,
    reason,
    votes: 0,
    color,
    preview: true,
    placement: 0,
    approved: false,
    campaign_id: 0,
  }

  return (
    <>
      <div className={styles.form}>
        <BillCard bill={previewBill} />

      </div>
      <div className={styles.actions}>
        <Button
          text="Tilbage"
          alternative={true}
          action={() => setStep(2)}
        />
        <Button
          text="Fortsæt"
          action={() => setStep(4)}
        />
      </div>
    </>
  )
}
