import React from 'react'
import Logo from '../Logo/Logo'
import DrtvLink from '../DrtvLink/DrtvLink'
import styles from './Footer.module.scss'

export default function Footer() {
  return (
    <footer className={styles.footer}>

      <DrtvLink />

      <h2>ULTRAFORSLAG</h2>
      <Logo />
    </footer>
  )
}
