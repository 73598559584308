import React from 'react'

interface Props {
  fill: string
  stroke: string
  [key: string]: any
}

export default function Icon(props: Props) {
  const { fill, stroke } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={28}
      height={32}
      {...props}
      fill={fill}
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth={4.063}
        d="M25.286 3C19.36 8.571 6.608 21.571 3 29"
      />
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth={4.063}
        d="M5.918 4.327c1.857 4.51 7.376 15.387 14.592 22.816"
      />
    </svg>
  )
}
