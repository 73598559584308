import React, { useEffect, useState } from 'react'
import Button from '../Button/Button'
import Input from '../Form/Input/Input'
import Checkbox from '../Form/Checkbox/Checkbox'
import styles from './NewBill.module.scss'

export default function Step4({
  setStep,
  fullname,
  setFullname,
  age,
  setAge,
  mobile,
  setMobile,
  parentFullname,
  setParentFullname,
  parentPhone,
  setParentPhone,
  permission,
  setPermission,
  setOpenConsent,
}: {
  setStep: (step: number) => void
  fullname: string
  setFullname: (fullname: string) => void
  age: string
  setAge: (age: string) => void
  mobile: string
  setMobile: (mobile: string) => void
  parentFullname: string
  setParentFullname: (parentFullname: string) => void
  parentPhone: string
  setParentPhone: (parentPhone: string) => void
  permission: boolean
  setPermission: (permission: boolean) => void
  setOpenConsent: (openConsent: boolean) => void
}) {
  const [errors, setErrors] = useState<string[]>([])

  const handleValidation = () => {
    let newErrors: string[] = []
    setErrors(newErrors)

    const fields: { [key: string]: string | boolean } = {
      fullname,
      age: age.toString(),
      mobile,
      parentFullname,
      parentPhone,
      permission,
    }

    const validationRules: { [key: string]: (value: string | boolean) => boolean } = {
      fullname: value => typeof value === 'string' && (value.length === 0 || value === parentFullname),
      age: (value) => {
        const intValue = Number.parseInt(value as string, 10)
        return Number.isNaN(intValue) || intValue < 1 || intValue > 17
      },
      mobile: value => typeof value === 'string' && (value.length !== 8),
      parentFullname: value => typeof value === 'string' && (value.length === 0 || value === fullname),
      parentPhone: value => typeof value === 'string' && (value.length !== 8),
      permission: value => !value,
    }

    Object.keys(validationRules).forEach((field) => {
      const isInvalid = validationRules[field as keyof typeof validationRules](fields[field as keyof typeof fields])
      if (isInvalid && !newErrors.includes(field))
        newErrors.push(field)
      else if (!isInvalid)
        newErrors = newErrors.filter(error => error !== field)
    })
    setErrors(newErrors)
    if (newErrors.length === 0)
      setStep(5)
  }

  const permissionLabel = (
    <>
      {`Jeg bekræfter, at jeg er over 18 år og har læst, forstået og accepteret
      `}
      <a onClick={() => {
        setTimeout(() => {
          setPermission(permission)
        }, 200)
        setOpenConsent(true)
      }}
      >
        Betingelserne for 'Ultraforslag',
      </a>
      {` samt DR's generelle `}
      <a
        href="https://www.dr.dk/om-dr/dr-og-dine-data/drs-privatlivspolitik"
        target="_blank"
        onClick={() => {
          setTimeout(() => {
            setPermission(permission)
          }, 200)
        }}
      >
        Privatlivspolitik.
      </a>
      {` Jeg indestår for, at forældremyndighedsindehaverne til det barn, der indsender Ultraforslaget, har givet tilladelse til indsendelsen i henhold til betingelserne.
      Ved delt/fælles forældremyndighed skal begge forældremyndighedsindehavere samtykke.`}
    </>
  )

  return (
    <>
      <div className={styles.form}>
        <p>Hvis dit forslag er blandt dem med flest stemmer og bliver kåret til årets Ultraforslag, skal vi bruge dine kontaktoplysninger.</p>
        <p>Disse oplysninger bliver ikke vist nogen steder.</p>

        <Input
          label="Dit fulde navn"
          name="fullname"
          type="text"
          value={fullname}
          placeholder="Skriv dit fulde navn her"
          setValue={setFullname}
          error={errors.includes('fullname') && 'Du skal indtaste dit fulde navn'}
          setError={() => setErrors(errors.filter(error => error !== 'fullname'))}
        />

        <Input
          label="Din alder"
          name="age"
          type="number"
          value={age}
          placeholder="Skriv hvor mange år du er, f.eks. 12"
          setValue={setAge}
          error={errors.includes('age') && 'Du skal indtaste din alder'}
          setError={() => setErrors(errors.filter(error => error !== 'age'))}
          maxLength={2}
          maxNumber={17}
          minNunber={1}
        />

        <Input
          label="Dit telefonnummer"
          name="mobile"
          type="tel"
          value={mobile}
          placeholder="12"
          maxLength={8}
          setValue={setMobile}
          error={errors.includes('mobile') && 'Du skal indtaste dit mobilnummer'}
          setError={() => setErrors(errors.filter(error => error !== 'mobile'))}
        />

        <Input
          label="Din forælders fulde navn"
          name="parentFullname"
          type="text"
          value={parentFullname}
          placeholder="Skriv din forælders fulde navn"
          setValue={setParentFullname}
          error={errors.includes('parentFullname') && 'Du skal indtaste din forælders fulde navn'}
          setError={() => setErrors(errors.filter(error => error !== 'parentFullname'))}
        />

        <Input
          label="Din forælders telefonnummer"
          name="parentPhone"
          type="tel"
          value={parentPhone}
          maxLength={8}
          placeholder="Skriv din forælders telefonnummer her"
          setValue={setParentPhone}
          error={errors.includes('parentPhone') && 'Du skal indtaste din forælders telefonnummer'}
          setError={() => setErrors(errors.filter(error => error !== 'parentPhone'))}
        />

        <Checkbox
          label={permissionLabel}
          name="permission"
          value={permission}
          setValue={setPermission}
          error={errors.includes('permission') && 'Du skal have lov af dine forældre'}
          setError={() => setErrors(errors.filter(error => error !== 'permission'))}
        />

        <div className={styles.errors}>
          {errors.includes('fullname') && <p>Du skal indtaste dit fulde navn</p>}
          {errors.includes('age') && <p>Du skal indtaste din alder, min 1 og max 17</p>}
          {errors.includes('mobile') && <p>Du skal indtaste dit mobilnummer</p>}
          {errors.includes('parentFullname') && <p>Du skal indtaste din forælders fulde navn</p>}
          {errors.includes('parentPhone') && <p>Du skal indtaste din forælders telefonnummer</p>}
        </div>

      </div>
      <div className={styles.actions}>
        <Button
          text="Tilbage"
          alternative={true}
          action={() => setStep(3)}
        />
        <Button
          submit={true}
          text="Indsend"
          action={(e: React.MouseEvent) => {
            e.preventDefault()
            handleValidation()
          }}
        />
      </div>
    </>
  )
}
