import React from 'react'
import styles from './Place.module.scss'

export default function Place({ number }: {
  number: number
}) {
  return (
    <div className={`${styles.place} ${number > 1000 ? styles.small : ''}`}>
      {number}
    </div>
  )
}
