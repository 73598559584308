import React, { useEffect, useState } from 'react'
import Icon from '../../Icon/Icon'
import styles from './Checkbox.module.scss'

export default function Checkbox({
  label,
  name,
  value,
  setValue,
  error,
  setError,
}: {
  label: React.ReactNode
  name: string
  value: boolean
  setValue: (value: boolean) => void
  error?: string | false
  setError: (error: string | false) => void
}) {
  return (
    <div className={`${styles.inputWrapper} ${error ? styles.error : ''}`}>
      {error && <div className={styles.errorMessage}>{error}</div>}

      <label
        htmlFor={name}
        className={styles.label}
        onClick={() => {
          setValue(!value)
          setError(false)
        }}
      >
        <span className={styles.checkbox}>
          {value && <Icon name="x" />}
        </span>
        <span>
          {label}
        </span>

      </label>
    </div>
  )
}
