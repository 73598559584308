import React from 'react'
import { router, usePage } from '@inertiajs/react'
import styles from './TabNavigation.module.scss'

export default function TabNavigation({ active }: {
  active: number
}) {
  const { campaign } = usePage().props

  const items = campaign ? [
    { id: 1, title: '⚡  Nyeste', route: '/' },
    { id: 2, title: '🎉 Trending', route: '/trending-bills' },
    { id: 3, title: '⌛ Tidligere', route: '/previous-bills' },
    // { id: 2, title: '🎉 Populære', route: '/popular-bills' },
  ] : [
    { id: 3, title: '⌛ Tidligere', route: '/previous-bills' },
  ]

  return (
    <nav className={styles.nav}>
      <div className={styles.inner}>
        {items.map(item => (
          <button
            key={item.id}
            className={`${styles.button} ${item.id === active ? styles.active : ''}`}
            onClick={() => router.get(item.route)}
          >
            {item.title}
          </button>
        ))}
      </div>
    </nav>
  )
}
