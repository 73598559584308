import React, { useState } from 'react'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import Icon from '../Icon/Icon'
import styles from './EmojiPicker.module.scss'

export default function EmojiPicker({
  emoji,
  setEmoji,
}: {
  emoji: string
  setEmoji: (emoji: string) => void
}) {
  const [visible, setVisible] = useState(false)

  return (
    <div className={styles.emojiPicker}>
      <div onClick={() => setVisible(!visible)}>
        {emoji || <div className={styles.icon}><Icon name="emoji" /></div>}
      </div>

      {visible && (
        <div className={styles.picker}>
          <Picker
            data={data}
            onEmojiSelect={(emojiObject: { native?: string }) => {
              setEmoji(emojiObject?.native || '')
              setVisible(false)
            }}
          />
        </div>
      )}
    </div>
  )
}
