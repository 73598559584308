import React from 'react'
import { router } from '@inertiajs/react'
import Button from '../Button/Button'
import Video from '../Video/Video'
import styles from './NewBill.module.scss'

export default function Step8() {
  return (
    <>
      <div className={styles.form}>
        <Video name="oplaegsvideo" autoplay={true} controls={false} />
        <h2>
          High-five!
          <br />
          Tak for dit forslag!
        </h2>
        <p>Nye forslag bliver offentliggjort løbende.</p>
      </div>

      <div className={styles.actions}>
        <Button
          text="ALLE forslag"
          action={() => router.get('/')}
          alternative={true}
        />
        <Button
          text="DINE FORSLAG"
          action={() => router.get('/my-bills')}
        />
      </div>
    </>
  )
}
