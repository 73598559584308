import React, { useState } from 'react'
import Button from '../Button/Button'
import Input from '../Form/Input/Input'
import { type BillColor, darkColors } from '../../types/bill.type'
import styles from './NewBill.module.scss'

export default function Step2({
  setStep,
  color,
  text,
  setText,
  reason,
  setReason,
  firstname,
  setFirstname,
  city,
  setCity,
}: {
  setStep: (step: number) => void

  color: BillColor
  text: string
  setText: (text: string) => void
  reason: string
  setReason: (reason: string) => void
  firstname: string
  setFirstname: (firstname: string) => void
  city: string
  setCity: (city: string) => void
}) {
  const [errors, setErrors] = useState<string[]>([])

  const handleValidation = () => {
    let newErrors: string[] = []

    const fields: { [key: string]: string } = {
      firstname,
      text,
      reason,
      city,
    }

    const validationRules: { [key: string]: (value: string) => boolean } = {
      text: value => value.length > 150 || value.length === 0,
      reason: value => value.length > 150 || value.length === 0,
      firstname: value => value.length === 0,
      city: value => value.length === 0,
    }

    Object.keys(validationRules).forEach((field) => {
      const isInvalid = validationRules[field as keyof typeof validationRules](fields[field as keyof typeof fields])
      if (isInvalid && !newErrors.includes(field))
        newErrors.push(field)
      else if (!isInvalid)
        newErrors = newErrors.filter(error => error !== field)
    })
    setErrors(newErrors)
    if (newErrors.length === 0)
      setStep(3)
  }

  return (
    <>
      <div className={styles.form}>
        <Input
          label="Skriv dit forslag"
          name="bill"
          type="text-area"
          value={text}
          placeholder="Hvad er dit Ultraforslag til politikerne? Skriv dit forslag til dét, du gerne vil forandre."
          helperText={`${text.length} ud af 150 tegn`}
          setValue={setText}
          error={(text.length > 150 || errors.includes('text')) && 'Må maks være 150 tegn'}
          setError={() => setErrors(errors.filter(error => error !== 'text'))}
          white={darkColors.includes(color)}
        />

        <Input
          label="Hvorfor er forslaget vigtigt for dig?"
          name="bill-reason"
          type="text-area"
          value={reason}
          placeholder="Skriv, hvorfor du har valgt lige netop dette forslag."
          helperText={`${reason.length} ud af 150 tegn`}
          setValue={setReason}
          error={(reason.length > 150 || errors.includes('reason')) && 'Må maks være 150 tegn'}
          setError={() => setErrors(errors.filter(error => error !== 'reason'))}
          white={darkColors.includes(color)}
        />

        <Input
          label="Dit fornavn"
          name="firstname"
          type="text"
          value={firstname}
          placeholder="Indtast dit fornavn"
          helperText="Skriv kun dit fornavn – ikke hele dit navn, da det er synligt for andre."
          setValue={setFirstname}
          error={errors.includes('firstname') && 'Du skal indtaste dit fornavn'}
          setError={() => setErrors(errors.filter(error => error !== 'firstname'))}
          white={darkColors.includes(color)}
        />

        <Input
          label="Hvilken by bor du i?"
          name="city"
          type="text"
          value={city}
          placeholder="Indtast navnet på byen"
          helperText="Skriv navnet på den by du bor i."
          setValue={setCity}
          error={errors.includes('city') && 'Du skal indtaste en by'}
          setError={() => setErrors(errors.filter(error => error !== 'city'))}
          white={darkColors.includes(color)}
        />

        <div className={styles.errors}>
          {errors.includes('text') && <p>Skriv dit forslag skal være udfyldt og maks 150 tegn</p>}
          {errors.includes('reason') && <p>Hvorfor er forslaget vigtigt for dig skal være udfyldt og maks 150 tegn</p>}
          {errors.includes('firstname') && <p>Du skal indtaste dit fornavn</p>}
          {errors.includes('city') && <p>Du skal indtaste en by</p>}
        </div>
      </div>

      <div className={styles.actions}>
        <Button
          text="Tilbage"
          alternative={true}
          action={() => setStep(1)}
        />
        <Button
          submit={true}
          text="Se dit forslag"
          action={(e: React.MouseEvent) => {
            e.preventDefault()
            handleValidation()
          }}
        />
      </div>
    </>
  )
}
