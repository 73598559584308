export async function vote(bill: number): Promise<boolean> {
  try {
    const response = await fetch('/api/vote', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        bill,
      }),
    })

    return response.status === 200
  }
  catch (error) {
    throw new Error('Failed to vote')
  }
}
