import React from 'react'
import Video from '../Video/Video'
import Overlay from '../Overlay/Overlay'
import styles from './Hero.module.scss'

export default function HeroVideo({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}) {
  return (

    <Overlay
      isOpen={isOpen}
      background="turquoise"
      setIsOpen={setIsOpen}
      title="Se her hvordan det virker"
      fitContent={true}
    >
      <div className={styles.introVideo}>
        <Video name="praemisvideo_2" autoplay={true} />
      </div>
    </Overlay>
  )
}
