import React from 'react'
import * as Icons from './icons/_index'
import styles from './Icon.module.scss'

export type IconNames = 'burger' | 'close' | 'x' | 'pen' | 'emoji'

interface Props {
  name: IconNames
  fill?: string
  stroke?: string
  background?: string
  size?: number
}

export default function Icon({ name, fill, stroke, background, size }: Props): JSX.Element | null {
  const IconComponent = Icons[name]
  const scaleStyle = size ? { transform: `scale(${size})`, transformOrigin: 'left' } : {}

  if (!IconComponent) {
    console.error(`Invalid icon name: ${name}`)
    return null
  }

  return (
    <div
      className={background ? styles.background : ''}
      style={{ backgroundColor: background || '' }}
    >
      <IconComponent
        fill={fill || 'none'}
        stroke={stroke || '#000'}
        style={scaleStyle}
      />
    </div>
  )
}
