import React from 'react'

import PageTitle from '../components/PageTitle/PageTitle'
import BillsList from '../components/BillsList/BillsList'
import type { pageProps } from '../types/page.type'
import TabNavigation from '../components/TabNavigation/TabNavigation'
import Hero from '../components/Hero/Hero'
import Layout from './Layout'

export default function Page({ bills }: pageProps) {
  return (
    <Layout>
      <TabNavigation active={2} />

      <Hero />

      <PageTitle title="Trending forslag" />

      <BillsList bills={bills} infoElementPlacement={10} />
    </Layout>
  )
}
