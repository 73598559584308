import React from 'react'
import { motion } from 'framer-motion'
import Icon from '../Icon/Icon'
import styles from './Overlay.module.scss'

export default function Overlay({
  isOpen,
  setIsOpen,
  background,
  title,
  children,
  direction,
  hideClose,
  fitContent,
}: {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  background: string
  title: string
  children: React.ReactNode
  direction?: 'bottom' | 'right'
  hideClose?: boolean
  fitContent?: boolean
}) {
  //   interface Animation { x?: string, y?: string }

  //   let initial: Animation = { x: '100%' }
  //   let animate: Animation = { x: isOpen ? '0' : '100%' }

  //   if (direction === 'bottom') {
  //     initial = { y: '100%' }
  //     animate = { y: isOpen ? '0%' : '100%' }
  //   }

  return (
  // <motion.div
  //   className={`${styles.overlay} ${styles[background]}`}
  //   initial={initial}
  //   animate={animate}

  //   transition={{ type: 'spring', stiffness: 200, damping: 20, duration: 0 }}
  // >
  //   <div className={styles.header}>
  //     <div className={styles.title}>{title}</div>
  //     {!hideClose && (
  //       <button
  //         className={styles.close}
  //         onClick={() => setIsOpen(false)}
  //       >
  //         <motion.div whileHover={{ scale: 1.3 }}><Icon name="close" /></motion.div>
  //       </button>
  //     )}
  //   </div>
  //   <div className={styles.content}>
  //     {children}
  //   </div>
  // </motion.div>

    isOpen && (
      <>
        <div className={`${styles.overlay} ${styles[background]} ${direction} ${fitContent ? styles.fitContent : ''}`}>
          <div className={styles.header}>
            <div className={styles.title}>{title}</div>
            {!hideClose && (
              <button
                className={styles.close}
                onClick={() => setIsOpen(false)}
              >
                <motion.div whileHover={{ scale: 1.3 }}><Icon name="close" /></motion.div>
              </button>
            )}
          </div>
          <div className={styles.content}>
            {children}
          </div>
        </div>
        <div className={styles.backdrop} onClick={() => setIsOpen(false)}></div>
      </>
    )
  )
}
