import React from 'react'

interface Props {
  fill: string
  stroke: string
  [key: string]: any
}

export default function Icon(props: Props) {
  const { fill, stroke } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={32}
      height={32}
      {...props}
      fill={fill}
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M8 10.667h16M12.667 16H24M10.667 21.333H24"
      />
    </svg>
  )
}
