import React from 'react'

import { router } from '@inertiajs/react'
import { useIsAuthenticated } from '../hooks/auth'
import BillsList from '../components/BillsList/BillsList'
import type { Bill } from '../types/bill.type'
import type { pageProps } from '../types/page.type'
import Video from '../components/Video/Video'
import Button from '../components/Button/Button'
import styles from './myBills.module.scss'
import Layout from './Layout'

export default function Page() {
  const participant = useIsAuthenticated()

  if (!participant)
    return router.get('/')

  const approvedBillsData: pageProps['bills'] = {
    data: (participant.bills.data || []).filter(bill => bill.approved).map((bill: Bill, index: number) => ({ ...bill, placement: index + 1 })),
    links: {
      first: null,
      last: null,
      next: null,
      prev: null,
    },
    meta: {
      current_page: 1,
      last_page: 1,
    },
  }

  const unapprovedBillsData: pageProps['bills'] = {
    data: (participant.bills.data || []).filter(bill => !bill.approved).map((bill: Bill, index: number) => ({ ...bill, preview: true, placement: index + 1 })),
    links: {
      first: null,
      last: null,
      next: null,
      prev: null,
    },
    meta: {
      current_page: 1,
      last_page: 1,
    },
  }

  return (
    <Layout headerTitle="Dine forslag">
      <div className={styles.myBills}>

        <div className={styles.hello}>
          <h2>{participant.firstname ? `Hej ${participant.firstname}!` : 'Hej!'}</h2>
          <h2>Her er dine forslag.</h2>
        </div>

        {approvedBillsData.data.length > 0
          ? (
            <BillsList bills={approvedBillsData} shareable={true} />
            )
          : (
            <div className={styles.noBills}>
              <Video name="waiting" autoplay={true} loop={true} controls={false} />

              <h3>{`${unapprovedBillsData.data.length > 1 ? 'Dine' : 'dit'} forslag er ved at blive godkendt.`}</h3>
            </div>
            )}

        {unapprovedBillsData.data.length > 0 && (
          <>
            <div className={styles.unapproved}>
              <h3>Dine forslag, som ikke er blevet godkendt endnu.</h3>

              <BillsList bills={unapprovedBillsData} />
            </div>
          </>
        )}

        <div className={styles.actions}>
          <Button
            text="se ALLE forslag"
            action={() => router.get('/')}
            alternative={true}
          />
        </div>

      </div>
    </Layout>
  )
}
