import React from 'react'
import { router } from '@inertiajs/react'
import Layout from '../Layout'
import BillCard from '../../components/Bill/Bill'
import type { Bill } from '../../types/bill.type'
import Button from '../../components/Button/Button'
import styles from './page.module.scss'

export default function Page({ bill }: { bill: { data: Bill } }) {
  return (
    <Layout>

      <div className={styles.bill}>
        <BillCard bill={bill.data} withPlace={false} />
        <div className={styles.actions}>
          <Button
            text="Se andre forslag"
            action={() => router.get('/')}
            alternative={true}
          />
        </div>
      </div>

    </Layout>
  )
}
