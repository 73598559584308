import React from 'react'
import styles from './Button.module.scss'

export default function Button({
  text,
  icon,
  action,
  disabled,
  alternative,
  white,
  submit,
}: {
  text: string
  icon?: React.ReactNode
  action?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void | Promise<void>
  disabled?: boolean
  alternative?: boolean
  white?: boolean
  submit?: boolean
}) {
  return (
    <button
      type={submit ? 'submit' : 'button'}
      className={`${styles.button} ${alternative ? styles.alternative : ''} ${white ? styles.white : ''}`}
      onClick={action as () => void}
      disabled={disabled}
    >
      {icon && icon}
      {text}
    </button>
  )
}
