import React from 'react'
import type { BillColor } from '../../types/bill.type'
import styles from './ColorPicker.module.scss'

export default function ColorPicker({
  color,
  colors,
  setColor,
}: {
  color: BillColor
  colors: BillColor[]
  setColor: (color: BillColor) => void
}) {
  return (
    <div className={`${styles.colorPicker} ${styles[color]}`}>
      <div className={`${styles.inner} ${styles[color]}`}>
        {colors.map(c => (
          <div
            key={c}
            className={`${styles.color} ${styles[c]} ${color === c ? styles.active : ''}`}
            onClick={() => setColor(c)}
          >
          </div>
        ),
        )}
      </div>
    </div>
  )
}
