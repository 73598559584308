import Cookies from 'js-cookie'
import { router } from '@inertiajs/react'

export interface ParticipantAuthResponse {
  message: string
  data: {
    token: string
  }
}

export async function login(mobile: string, pin: string, callbackUrl: string): Promise<number> {
  try {
    const response = await fetch('/api/participant/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        mobile,
        pin,
      }),
    })

    if (response.status === 200) {
      const body = await response.json() as ParticipantAuthResponse
      Cookies.set('token', body.data.token)
      router.get(callbackUrl, {}, { preserveScroll: true })
    }
    return response.status
  }
  catch (error) {
    throw new Error('Error logging in')
  }
}

export async function signup(mobile: string, pin: string, callbackUrl: string): Promise<boolean> {
  try {
    const response = await fetch('/api/participant/signup', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        mobile,
        pin,
      }),
    })

    if (response.status === 200) {
      const body = await response.json() as ParticipantAuthResponse
      Cookies.set('token', body.data.token)
      router.get(callbackUrl, {}, { preserveScroll: true })
    }

    return response.status === 200
  }
  catch (error) {
    throw new Error('Error signing up')
  }
}

export function logout(callbackUrl: string): void {
  try {
    Cookies.remove('token')
    router.get(callbackUrl, {}, { preserveScroll: true })
  }
  catch (error) {
    throw new Error('Error removing token')
  }
}

export async function forgotPin(mobile: string): Promise<boolean> {
  try {
    const response = await fetch('/api/participant/forgot-pin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        mobile,
      }),
    })

    return response.status === 200
  }
  catch (error) {
    throw new Error('Error sending forgot pin request')
  }
}

export async function exists(mobile: string): Promise<number> {
  try {
    const response = await fetch('/api/participant/exists', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify({
        mobile,
      }),
    })

    return response.status
  }
  catch (error) {
    throw new Error('Error sending forgot pin request')
  }
}
