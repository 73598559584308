import React from 'react'
import Overlay from '../Overlay/Overlay'
import styles from './Paused.module.scss'

export default function Paused({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}) {
  return (
    <Overlay
      isOpen={isOpen}
      background="turquoise"
      setIsOpen={setIsOpen}
      title="Sommerferie"
      fitContent={true}
    >
      <div className={styles.container}>
        <h2 className={styles.header}>
          {/* Ultraforslag holder sommerferie og er tilbage igen i august! */}
          Ultraforslag er tilbage i foråret 2025. Indtil da kan du se mere
          {' '}
          <a className={styles.link} href="https://www.dr.dk/skole/dansk/mellemtrin/tema/ultraforslag">her</a>
          .
        </h2>
      </div>
    </Overlay>
  )
}
