import React from 'react'

import Overlay from '../Overlay/Overlay'
import styles from './Consent.module.scss'

export default function Consent({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}) {
  return (
    <Overlay isOpen={isOpen} background="white" setIsOpen={setIsOpen} title="Vilkår for deltagelse i Ultraforslag">
      <div className={styles.content}>
        <h3>Vilkår for deltagelse i Ultraforslag</h3>
        <h4>Om Ultraforslag</h4>
        <p>Kampagnens formål er at styrke danske børn og unges demokratiske selvtillid. 'Ultraforslag' skal lade børnene komme til orde samt stemme og bestemme.</p>
        <p>Børn og unge under 18 år kan indsende Ultraforslag og stemme på Ultraforslag.</p>
        <p>Det forslag, der vinder, bliver kåret til årets Ultraforslag og bliver præsenteret for nogle af Danmarks toppolitikere.</p>
        <p>Samarbejdspartnere på 'Ultraforslag' er landets biblioteker og Folketinget.</p>

        <h4>Indsendelse af forslag</h4>
        <ul>
          <li>Forslag kan indsendes fra den 5. juni 2024.</li>
          <li>
            Indsendelse sker via formularen, der findes på
            {' '}
            <a href="https://dr.dk/Ultraforslag" target="_blank">www.dr.dk/Ultraforslag</a>
          </li>
          <li>Børnene skal være 0-17 år. Personer, der er fyldt 18 år, kan ikke deltage.</li>
          <li>Ultraforslag må IKKE indeholde hadbeskeder, oplysninger om identificerbare personer, mobning, indhold, der i øvrigt er i strid med lovgivningen eller lignende.</li>
          <li>Der skal være en person på minimum 18 år, der hjælper barnet med at indsende forslaget. Den person, der hjælper med at indsende forslaget, indestår samtidig for, at forældremyndighedsindehaverne til det barn, der har fundet på forslaget, har givet tilladelse til at sende forslaget i henhold til nærværende vilkår. Ved delt/fælles forældremyndighed skal begge forældremyndighedsindehavere samtykke. Hvis én forælder/værge har fuld forældremyndighed, skal kun denne give sin tilladelse.</li>
          <li>Der kan indsendes flere forslag per deltager, men hvert forslag skal indsendes enkeltvis.</li>
          <li>Man kan stemme på alle Ultraforslag én gang. Man kan ikke stemme flere gange på ét forslag.</li>
          <li>Mindst en gang dagligt på arbejdsdage bliver indsendte forslag offentliggjort på hjemmesiden. Indsendte forslag bliver inden offentliggørelsen godkendt af en moderator, som holder øje med, at forslaget ikke er i strid med vilkårene.</li>
          <li>DR forbeholder sig retten til ikke at godkende forslag, der ikke er udfyldt korrekt, eller er i strid med disse vilkår.</li>
          <li>Når et forslag bliver offentliggjort, fremgår fornavn og by på den, der har indsendt forslaget, samt selve det skrevne forslag. Det vil også fremgå, hvor mange stemmer forslaget løbende har fået. Personlige oplysninger som efternavn, telefonnummer og kontaktoplysninger til forældre er ikke synligt på forslaget.</li>
        </ul>

        <h4>Afstemning</h4>
        <p>Alle under 18 år kan stemme på alle Ultraforslag én gang. Man kan ikke stemme flere gange på samme forslag. Man skal være logget ind på hjemmesiden for at stemme og oprette forslag.</p>

        <h4>Årets Ultraforslag</h4>
        <p>Det barn, hvis forslag vinder, bliver kåret til Årets Ultraforslag. Dette bliver dækket af DR til brug for publicering på DR’s platforme. DR sender en samtykkeerklæring, som skal bruges til dette formål, som skal underskrives af forældremyndighedsindehaverne til det barn, der har fundet på forslaget. Som det fremgår ovenfor, skal den person, der hjælper med at indsende forslaget, dog allerede inden tilmeldingen sikre sig, at forældrene har samtykket til indsendelse af forslaget.</p>

        <h4>Ansvar</h4>
        <p>DR tilstræber, at information og indhold vedrørende Ultraforslag til enhver tid er opdateret og korrekt, men kan dog ikke garantere, at indholdet er fejlfrit og komplet.</p>
        <p>Alle tvivlsspørgsmål, der må opstå i forbindelse med fortolkningen af disse vilkår, afgøres af DR.</p>

        <h4>Kontakt</h4>
        <a href="mailto:DRUltraforslag@dr.dk" target="_blank">DRUltraforslag@dr.dk</a>

        <h4>Databeskyttelse</h4>
        <p>DR's indsamling af personoplysninger sker i overensstemmelse med den gældende lovgivning, herunder databeskyttelsesforordningen (GDPR).</p>
        <p>Ved tilmelding gives der samtykke til, at det indsendte indhold (hvis det indeholder personoplysninger) og de afgivne personoplysninger (kontaktinformationer m.v.) registreres og opbevares af DR og DR's databehandler Signifly ApS for at opfylde ovennævnte formål. Databehandleren registrerer og behandler udelukkende personoplysninger på DR's vegne for at indholdet kan anvendes af DR, og databehandleren kan således ikke selvstændigt råde over disse. </p>
        <p>DR's behandling af oplysningerne sker udelukkende til journalistiske formål og er dermed ikke fuldt ud omfattet af reglerne i GDPR. Du har fx ikke ret til at få oplysningerne slettet efter reglerne i GDPR om ”retten til at blive glemt” m.v. </p>

        <p>
          Du kan læse mere om DR's privatlivspolitik
          {' '}
          <a href="https://www.dr.dk/om-dr/dr-og-dine-data/drs-privatlivspolitik" target="_blank">her</a>
          .
        </p>
      </div>
    </Overlay>
  )
}
