import React from 'react'

interface Props {
  fill: string
  stroke: string
  [key: string]: any
}

export default function Icon(props: Props) {
  const { _fill, stroke } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      {...props}
      fill="none"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth={2.4}
        d="M21.822 8.056a12.173 12.173 0 0 0-1.827-.137c-6.67 0-12.078 5.407-12.078 12.078 0 6.67 5.407 12.078 12.078 12.078 6.67 0 12.078-5.407 12.078-12.078 0-.621-.047-1.231-.137-1.827"
      />
      <circle cx={16.249} cy={17.312} r={1.599} fill={stroke} />
      <circle cx={23.741} cy={17.312} r={1.599} fill={stroke} />
      <path
        fill={stroke}
        d="M15.428 22.175c0-.194.157-.351.35-.351h8.433c.194 0 .351.157.351.351a4.216 4.216 0 0 1-4.216 4.216h-.703a4.216 4.216 0 0 1-4.216-4.216Z"
      />
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth={2.4}
        d="M29.13 7.208v7.308M25.476 10.862h7.307"
      />
    </svg>
  )
}
