import React from 'react'
import styles from './DrtvLink.module.scss'

export default function DrtvLink({ title }: { title?: boolean }) {
  return (
    <a className={styles.videoLink} href="https://www.dr.dk/drtv/liste/451032" target="_blank">
      {title && <div className={styles.title}>Se videoer om Ultraforslag på DRTV</div>}
      <div className={styles.images}>
        <img src="/images/footer.jpg" alt="dr ultra forslag" />
        <img className={styles.play} src="/images/play.png" alt="Video play" />
      </div>
      {!title && <div className={styles.subTitle}>Se videoer om Ultraforslag på DRTV</div>}
    </a>
  )
}
